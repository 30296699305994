export const EnglishCustom = {
  "weekdays": {
    "shorthand": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    "longhand": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
  },
  "months": {
    "shorthand": [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    "longhand": [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  },
  "daysInMonth": [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
  "firstDayOfWeek": 0,
  "rangeSeparator": " to ",
  "weekAbbreviation": "Wk",
  "scrollTitle": "Scroll to increment",
  "toggleTitle": "Click to toggle",
  "amPM": ["AM", "PM"],
  "yearAriaLabel": "Year",
  "monthAriaLabel": "Month",
  "hourAriaLabel": "Hour",
  "minuteAriaLabel": "Minute",
  "time_24hr": false,
};