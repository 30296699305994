import BSN from "bootstrap.native"
import Tabulator from "tabulator-tables";
import AutoNumeric from 'autonumeric';
import { ApplicationController } from "../application_controller";

Tabulator.prototype.extendModule("columnCalcs", "calculations", {
  "wagesPaymentsTotalText": function (values, data, calcParams) {
    const table = document.getElementById("wages_payments_table")
    if (table)
      return table.getAttribute("data-total-text")
  },
  "wagesPaymentsTotalAmount": function (values, data, calcParams) {
    const table = document.getElementById("wages_payments_table")
    if (!table) return 0;
    let total_html = table.getAttribute("data-total-amount");
    return total_html;
  },
  "wagesPaymentsTotalPaid": function (values, data, calcParams) {
    const table = document.getElementById("wages_payments_table")
    if (!table) return 0;
    let total_html = table.getAttribute("data-total-paid");
    return total_html;
  },
  "wagesPaymentsTotalBalance": function (values, data, calcParams) {
    const table = document.getElementById("wages_payments_table")
    if (!table) return 0;
    let total_html = table.getAttribute("data-total-balance");
    return total_html;
  }
});


export default class extends ApplicationController {
  static targets = [ "sidebar_table", "index_table",
    "search", "department_ids",
    "transaction_currency_radio_template", "modal_currencies_radio",
    "modal_currency_text",
    "to_main_rate_field", "from_main_rate_field",
    "to_additional_rate_field", "from_additional_rate_field"
  ]

  connect() {
    if (this.hasSidebar_tableTarget) {
      const content_url = this.sidebar_tableTarget.getAttribute("data-table-load-url");
      this.createSidebarTable(content_url);
    }

    if (this.hasIndex_tableTarget) {
      const content_url = this.index_tableTarget.getAttribute("data-table-load-url");
      this.createIndexTable(content_url);
    }
  }

  onPostPutSuccess(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      // show notify
      window.vNotify.success({ text: data.notification_message, title: data.notification_title });

      const rowId = data.id;
      const rowData = data.data;

      // update table data
      this.updateTable(rowId, rowData, { total_paid:    data.total_paid,
                                         total_balance: data.total_balance });

      window.layout_modal_md.hide();
    }
  }

  onPostPutError(event) {
    const [data, status, xhr] = event.detail;
    window.layout_modal_md.setContent(data.form_content)
  }

  openModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");

    let filters = []
    if (this.hasSearchTarget && this.searchTarget.value != '') {
      filters.push("q=" + this.searchTarget.value);
    }
    if (this.hasDepartment_idsTarget && this.department_idsTarget.choices.getValue(true) != '') {
      filters.push("department_ids=" + this.department_idsTarget.choices.getValue(true));
    }
    filters = filters.join("&")
    if (filters.length) {
      url += (url.search(/\?/) > 0 ? "&" : "?") + filters
    }

    this.openLayoutModalMD(url);
  }


  updateTransactionCurrencies(event) {
    let currencies = event.currentTarget.getAttribute("data-account-currencies");
    let li_template = this.transaction_currency_radio_templateTarget.innerHTML;
    let currencies_ul = this.modal_currencies_radioTarget;

    currencies_ul.innerHTML = "";
    if (currencies) {
      JSON.parse(currencies).forEach(el => {
        let li = document.createElement("li");
        let currency_class = (el["disabled"] == true ? "disabled" : "")
        let li_html = li_template.replace(/TRANSACTION_AMOUNT_CURRENCY_CODE/g, el["currency"]);
        li_html = li_html.replace(/TRANSACTION_AMOUNT_CURRENCY_CLASS/g, currency_class);
        li_html = li_html.replace(/\"TRANSACTION_CURRENCY_INFO\"/g, JSON.stringify(el));

        li.classList.add("nav-item");
        li.innerHTML = li_html;
        currencies_ul.appendChild(li);
      });

      let currency = currencies_ul.querySelector("li:first-child input[type=radio]");
      currency.checked = true;

      this.updateTransactionInputCurrencies(currency);
    }
  }

  updateCurrencyFields(event) {
    const currency = event.currentTarget;

    this.updateTransactionInputCurrencies(currency);
  }

  updateTransactionInputCurrencies(element) {
    let currency = element.getAttribute("value");
    let info_data = element.getAttribute("data-currency-info");
    let to_main_field = this.to_main_rate_fieldTarget;
    let from_main_field = this.from_main_rate_fieldTarget;
    let to_additional_field = this.to_additional_rate_fieldTarget;
    let from_additional_field = this.from_additional_rate_fieldTarget;

    this.modal_currency_textTarget.innerHTML = currency;

    if (info_data){
      info_data = JSON.parse(info_data);

      if (info_data["is_main"] == true){
        to_main_field.classList.add("hidden");
        from_main_field.classList.add("hidden");

        AutoNumeric.set(to_main_field.querySelector(".input-group input"), 1.0);
        AutoNumeric.set(from_main_field.querySelector(".input-group input"), 1.0);
      } else {
        to_main_field.classList.remove("hidden");
        from_main_field.classList.remove("hidden");

        to_main_field.querySelector("label.control-label span.text").innerHTML = info_data["label"] + ' = ';
        AutoNumeric.set(to_main_field.querySelector(".input-group input"), info_data["to_main_rate"] || 1.0);

        from_main_field.querySelector(".input-group span.input-group-text").innerHTML = currency;
        AutoNumeric.set(from_main_field.querySelector(".input-group input"), info_data["from_main_rate"] || 1.0);
      }

      if (info_data["is_additional"] == true){
        to_additional_field.classList.add("hidden");
        from_additional_field.classList.add("hidden");

        AutoNumeric.set(to_additional_field.querySelector(".input-group input"), 1.0);
        AutoNumeric.set(from_additional_field.querySelector(".input-group input"), 1.0);
      } else {
        to_additional_field.classList.remove("hidden");
        from_additional_field.classList.remove("hidden");

        to_additional_field.querySelector("label.control-label span.text").innerHTML = info_data["label"] + ' = ';
        AutoNumeric.set(to_additional_field.querySelector(".input-group input"), info_data["to_additional_rate"] || 1.0);

        from_additional_field.querySelector(".input-group span.input-group-text").innerHTML = currency;
        AutoNumeric.set(from_additional_field.querySelector(".input-group input"), info_data["from_additional_rate"] || 1.0);
      }
    }
  }



  filterTable() {
    this.setFilterToTable();
  }

  setFilterToTable() {
    let table = this.index_tableTarget.tabulator;
    let filters = [];

    if (this.hasSearchTarget) {
      filters.push({
        field: "q",
        type: "=",
        value: this.searchTarget.value
      })
    }

    if (this.hasDepartment_idsTarget) {
      filters.push({
        field: "department_ids",
        type: "=",
        value: this.department_idsTarget.choices.getValue(true)
      })
    }

    if (filters.length) {
      table.setFilter(filters);
    }
  }

  async updateTable(rowId, rowData, totals) {
    const mainTable = document.getElementById("wages_payments_table");
    const mainTabulator = mainTable.tabulator;

    mainTable.setAttribute("data-total-paid", totals["total_paid"]);
    mainTable.setAttribute("data-total-balance", totals["total_balance"]);

    mainTabulator.updateRow(rowId, rowData);
  }


  async createIndexTable(url) {
    const table_target = this.index_tableTarget;
    const columns = table_target.getAttribute("data-table-columns");
    const default_props = super.tabulatorDefaultProps;
    const height = document.getElementById('content').clientHeight;
    const sort_column = table_target.getAttribute("data-sort-column");
    const sort_dir = table_target.getAttribute("data-sort-dir");

    let table_props = {
      persistenceID: "wages_payments_table",
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: (height - 150) + "px",
      movableColumns: true,
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],
      // columns
      columns: JSON.parse(columns),
      // pagination
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      paginationSizeSelector: [10, 25, 50],
      pagination: "remote",
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",

      ajaxResponse:function(url, params, response){
        const table = document.getElementById("wages_payments_table")
        table.setAttribute("data-total-amount", response.total_amount);
        table.setAttribute("data-total-paid", response.total_paid);
        table.setAttribute("data-total-balance", response.total_balance);

        return response;
      }
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }


  // create sidebar
  async createSidebarTable(url) {
    const table_target = this.sidebar_tableTarget;
    const columns = table_target.getAttribute("data-table-columns");
    const per_page = table_target.getAttribute("data-per-page");

    //
    let loadSelected = this.createContent
    let puthToTurbolinks = this.historyPush

    let table_props = {
      persistenceID: "wages_payments_sidebar_table",
      layout: "fitDataStretch",
      headerSort: false,
      headerVisible: false,
      height: "100%",
      // columns
      columns: JSON.parse(columns),
      // pagination
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 100,
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxProgressiveLoad: "scroll",
      //
      rowFormatter: function (row) {
        //row - row component
        const data = row.getData();
        if (data.url) row.getElement().dataset.url = data.url;
        if (data.history_url) row.getElement().dataset.history_url = data.history_url;

        if (data.is_selected) {
          row.select();
        }
      },
      rowClick: function (e, row) {
        const content_url = row.getElement().dataset.url;
        const history_url = row.getElement().dataset.history_url;
        loadSelected(content_url);
        let row_table = row.getTable();
        row_table.deselectRow();
        row.select();
        puthToTurbolinks(history_url);
      }

    }
    // table props
    const default_props = super.tabulatorDefaultProps
    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }

  async createContent(content_url) {
    const content_target = document.getElementById("content");
    let content_response = await fetch(content_url);
    let content = await content_response.text();
    content_target.innerHTML = content;
    super.updateSidebarStick();
  }


}