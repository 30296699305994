import BSN from "bootstrap.native"
import Tabulator from "tabulator-tables";
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["index_table",
    "filter_period", "filter_transaction_type", "filter_purpose",
    "filter_account", "filter_pay_type", "filter_associated"
  ]

   connect() {
    // Main table
    if (this.hasIndex_tableTarget) {
      const content_url = this.index_tableTarget.getAttribute("data-table-load-url");
      this.createTable(content_url);
    }
  }

  filterTable() {
    this.setFilterToTable();
  }

  setFilterToTable() {
    let table = this.index_tableTarget.tabulator;
    let filters = []

    if (this.hasFilter_periodTarget) {
      filters.push({
        field: "report_period",
        type: "=",
        value: this.filter_periodTarget.value
      })
    }

    if (this.hasFilter_transaction_typeTarget) {
      filters.push({
        field: "transaction_type_ids",
        type: "=",
        value: this.filter_transaction_typeTarget.choices.getValue(true)
      })
    }

    if (this.hasFilter_purposeTarget) {
      filters.push({
        field: "purpose_ids",
        type: "=",
        value: this.filter_purposeTarget.choices.getValue(true)
      })
    }

    if (this.hasFilter_accountTarget) {
      filters.push({
        field: "account_ids",
        type: "=",
        value: this.filter_accountTarget.choices.getValue(true)
      })
    }

    if (this.hasFilter_pay_typeTarget) {
      filters.push({
        field: "pay_type_ids",
        type: "=",
        value: this.filter_pay_typeTarget.choices.getValue(true)
      })
    }

    if (this.hasFilter_associatedTarget) {
      filters.push({
        field: "associated_ids",
        type: "=",
        value: this.filter_associatedTarget.choices.getValue(true)
      })
    }

    if (filters.length) {
      table.setFilter(filters);
    }
  }


  async createTable(url) {
    const table_target = this.index_tableTarget;
    const columns = table_target.getAttribute("data-table-columns");

    const default_props = super.tabulatorDefaultProps
    const height = document.getElementById('content').clientHeight;

    let table_props = {
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: "100%",
      persistenceID: "transactions_report_table",
      movableColumns: true,
      // columns
      columns: JSON.parse(columns),
      headerSort: false,
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 100,
      // Ajax
      ajaxURL: url,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxProgressiveLoad: "scroll",
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",

      dataTree: true,
      dataTreeStartExpanded: true,
      dataTreeBranchElement: false,
      dataTreeExpandElement: "<i class='fas fa-chevron-up'></i>",
      dataTreeCollapseElement: "<i class='fas fa-chevron-down'></i>",

      rowFormatter:function(row){
        const data = row.getData();

        if (data.status) {
          row.getElement().classList.add("table-" + data.status)
        }
      },

      ajaxResponse:function(url, params, response){
        const table = document.getElementById("transactions_report_table");
        table.tabulator.setColumns(response.table_columns);

        return response;
      },

      rowClick:function(e, row){
        row.treeToggle();
      },

      dataTreeRowExpanded:function(row, level){
        row.getElement().querySelectorAll(".group_row").forEach(function(el) {
          el.classList.add('hidden');
        });
      },

      dataTreeRowCollapsed:function(row, level){
        row.getElement().querySelectorAll(".group_row").forEach(function(el) {
          el.classList.remove('hidden');
        });
      }
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }


}