import { Controller } from "stimulus";
import { getMetaValue } from "helpers";
import Tabulator from 'tabulator-tables';
import AutoNumeric from 'autonumeric';
import { Draggable } from 'agnostic-draggable';

export class ApplicationController extends Controller {

  historyPush(url) {
    Turbolinks
      .controller
      .pushHistoryWithLocationAndRestorationIdentifier(url, Turbolinks.uuid())

  }

  objectToQueryString(params) {
    return Object.keys(params).map(key => key + '=' + params[key]).join('&');
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  toggleUpdate(url, field, value, toggle_target) {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      data.append(field, value);

      fetch(url, {
        body: data,
        method: "PUT",
        dataType: "script",
        credentials: "include",
        headers: {
          "X-CSRF-Token": getMetaValue("csrf-token")
        },
      }).then(function (response) {
        if (response.status != 204) {
          toggle_target.checked = !toggle_target.checked
        }
      })
    })
  }

  //
  //
  //
  setNumericFormat(value) {
    try {
      if (!value)
        return 0;
      return AutoNumeric.format(value, {
        allowDecimalPadding: "floats",
        decimalPlaces: 2,
        digitGroupSeparator: " ",
        decimalCharacter: ".",
        decimalCharacterAlternative: ",",
        styleRules: {
          positive: "autoNumeric-positive"
        },
        minimumValue: 0
      });
    } catch (err) {
      return 0;
    }
  }

  draggable_modal(query_selector) {
    let modal_win = document.querySelector(query_selector);
    let appendTo = modal_win.parentElement.parentElement;
    // new Draggable(modal_win, {appendTo: appendTo, containment: 'window', cursor: 'move'});
    new Draggable(modal_win, {appendTo: appendTo, axis: 'x', cursor: 'move'});
  }

  // open modal and load modal content from url
  // let url = this.data.get("new-url");
  // this.openLayoutModal(url);
  async openLayoutModal(content_url) {
    // await - https://learn.javascript.ru/async-await#await
    let content_response = await fetch(content_url);
    let content = await content_response.text();
    //
    // https://thednp.github.io/bootstrap.native/#componentModal
    //
    if (window.layout_modal) {
      window.layout_modal.setContent(content);
      window.layout_modal.show();
      this.draggable_modal('#layout_modal .modal-content');
    }
  }

  async openLayoutModalMD(content_url) {
    // await - https://learn.javascript.ru/async-await#await
    let content_response = await fetch(content_url);
    let content = await content_response.text();
    //
    // https://thednp.github.io/bootstrap.native/#componentModal
    //
    if (window.layout_modal_md) {
      window.layout_modal_md.setContent(content);
      window.layout_modal_md.show();
      this.draggable_modal('#layout_modal_md .modal-content');
    }
  }

  async openLayoutModalLG(content_url) {
    // await - https://learn.javascript.ru/async-await#await
    let content_response = await fetch(content_url);
    let content = await content_response.text();
    //
    // https://thednp.github.io/bootstrap.native/#componentModal
    //
    if (window.layout_modal_lg) {
      window.layout_modal_lg.setContent(content);
      window.layout_modal_lg.show();
      this.draggable_modal('#layout_modal_lg .modal-content');
    }
  }

  async openLayoutModalXL(content_url) {
    // await - https://learn.javascript.ru/async-await#await
    let content_response = await fetch(content_url);
    let content = await content_response.text();
    //
    // https://thednp.github.io/bootstrap.native/#componentModal
    //
    if (window.layout_modal_xl) {
      window.layout_modal_xl.setContent(content);
      window.layout_modal_xl.show();
      this.draggable_modal('#layout_modal_xl .modal-content');
    }
  }

  async openGruopLayoutModalXL(content_url, ids) {
    // await - https://learn.javascript.ru/async-await#await

    // query
    let params = {
      ids: ids
    }

    content_url += '?' + this.objectToQueryString(params);

    let content_response = await fetch(content_url);
    let content = await content_response.text();
    //
    // https://thednp.github.io/bootstrap.native/#componentModal
    //
    if (window.layout_modal_xl) {
      window.layout_modal_xl.setContent(content);
      window.layout_modal_xl.show();
      this.draggable_modal('#layout_modal_xl .modal-content');
    }
  }


  setTabulatorTable() {
    let table = new Tabulator("#tabulator-table", {
      height: "100%",
      dataTree: true,
      dataTreeStartExpanded: true,
      layout: "fitColumns",
      resizableRows: true
    });

    return table;
  }

  async updateSidebarStick() {
    const sidebar = document.querySelector('#sidebar');
    if (sidebar) {
      await sidebar.sidebar.updateSticky();
      // console.log("updateSidebarStick")
    }
  }

  get appLocale() {
    return document.body.getAttribute("data-lang");
  }

  preferredCountries() {
    return JSON.parse(document.body.getAttribute("data-preferred-countries"));
  }

  get tabulatorDefaultProps() {
    let props = {
      // language
      langs: {
        "ru": {
          "pagination": {
            "page_size": "Кол-во на странице", //label for the page size select element
            "page_title": "Количество записей на странице",//tooltip text for the numeric page button, appears in front of the page number (eg. "Show Page" will result in a tool tip of "Show Page 1" on the page 1 button)
            "first": "Первая", //text for the first page button
            "first_title": "Первая страница", //tooltip text for the first page button
            "last": "Последная",
            "last_title": "Последная страница",
            "prev": "Предыдущий",
            "prev_title": "Предыдущая страница",
            "next": "Следующий",
            "next_title": "Следующя страница",
            "all": "Все",
          },
          "ajax": {
            "loading": "Загрузка...", //ajax loader text
            "error": "Ошибка", //ajax error text
          }
        },
        "en": {
          "pagination": {
            "page_size": "Page Size", //label for the page size select element
            "page_title": "Show Page",//tooltip text for the numeric page button, appears in front of the page number (eg. "Show Page" will result in a tool tip of "Show Page 1" on the page 1 button)
            "first": "First", //text for the first page button
            "first_title": "First Page", //tooltip text for the first page button
            "last": "Last",
            "last_title": "Last Page",
            "prev": "Prev",
            "prev_title": "Prev Page",
            "next": "Next",
            "next_title": "Next Page",
            "all": "All",
          },
          "ajax": {
            "loading": "Loading...", //ajax loader text
            "error": "Error", //ajax error text
          }
        },
      },
      // layout
      persistence: {
        sort: true,
        filter: false,
        group: false,
        page: {
          size: true, //persist the current page size
          page: false, //do not persist the current page
        },
        columns: true
      },
      layout: "fitDataFill",
      virtualDom: true,
      virtualDomHoz: true,
      resizableColumns: "header",
      resizableRows: false,
      maxHeight: "100%",
      autoResize: true
    }
    return props
  }

}

Tabulator.prototype.extendModule("format", "formatters", {
  column_wrap:function(cell, formatterParams){
    cell.getElement().classList.add("column-wrap");
    return cell.getValue();
  },
});